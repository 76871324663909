// src/datadog.nextjs.ts
import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";
var excludeAuthError = (event) => {
  if (event.type === "error") {
    const accessTokenExpired = event.error.message.includes("460");
    const accessTokenNotexist = event.error.message.includes("401");
    if (accessTokenExpired || accessTokenNotexist) {
      return false;
    }
  }
  return true;
};
function datadogNextjs(option) {
  const initDatadog = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) {
      datadogRum.init({
        applicationId: (_a = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) != null ? _a : "",
        clientToken: (_b = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) != null ? _b : "",
        site: "datadoghq.com",
        service: (_c = process.env.NEXT_PUBLIC_DATADOG_APPLICATION) != null ? _c : "",
        env: (_d = process.env.NEXT_PUBLIC_DATADOG_ENV) != null ? _d : "",
        version: (_e = option == null ? void 0 : option.packageVersion) != null ? _e : "",
        sessionSampleRate: (_f = option == null ? void 0 : option.sessionSampleRate) != null ? _f : 5,
        sessionReplaySampleRate: (_g = option == null ? void 0 : option.sessionReplaySampleRate) != null ? _g : 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        allowedTracingUrls: [
          `${process.env.NEXT_PUBLIC_API_DOMAIN}`,
          `${process.env.NEXT_PUBLIC_CDN_DOMAIN}`
        ],
        beforeSend: (option == null ? void 0 : option.excludeAuthError) ? excludeAuthError : void 0
      });
      datadogRum.startSessionReplayRecording();
    }
  };
  const getIsNetworkError = (error) => axios.isAxiosError(error);
  const addErrorWithConfig = (error) => {
    var _a, _b, _c, _d, _e;
    if (getIsNetworkError(error)) {
      datadogRum.addError(error, {
        type: "network",
        statusCode: (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.status,
        resource: (_b = error == null ? void 0 : error.config) == null ? void 0 : _b.url,
        method: (_c = error == null ? void 0 : error.config) == null ? void 0 : _c.method,
        data: (_d = error == null ? void 0 : error.config) == null ? void 0 : _d.data,
        response: (_e = error == null ? void 0 : error.response) == null ? void 0 : _e.data,
        pathname: window.location.pathname,
        href: window.location.href
      });
    } else {
      datadogRum.addError(error, {
        type: "client",
        path: window.location.pathname,
        href: window.location.href
      });
    }
  };
  return {
    initDatadog,
    addErrorWithConfig,
    getIsNetworkError
  };
}

// src/datadog.nuxtjs.ts
import { datadogRum as datadogRum2 } from "@datadog/browser-rum";
var excludeAuthError2 = (event) => {
  if (event.type === "error") {
    const accessTokenExpired = event.error.message.includes("460");
    const accessTokenNotexist = event.error.message.includes("401");
    if (accessTokenExpired || accessTokenNotexist) {
      return false;
    }
  }
  return true;
};
function datadogNuxtjs(option) {
  const initDatadog = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (process.env.DATADOG_APPLICATION_ID) {
      datadogRum2.init({
        applicationId: (_a = process.env.DATADOG_APPLICATION_ID) != null ? _a : "",
        clientToken: (_b = process.env.DATADOG_CLIENT_TOKEN) != null ? _b : "",
        site: "datadoghq.com",
        service: (_c = process.env.DATADOG_APPLICATION) != null ? _c : "",
        env: (_d = process.env.DATADOG_ENV) != null ? _d : "",
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        version: (_e = option == null ? void 0 : option.packageVersion) != null ? _e : "",
        sessionSampleRate: (_f = option == null ? void 0 : option.sessionSampleRate) != null ? _f : 100,
        sessionReplaySampleRate: (_g = option == null ? void 0 : option.sessionReplaySampleRate) != null ? _g : 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [
          `${process.env.API_DOMAIN}`,
          `${process.env.VEHICLE_API_DOMAIN}`,
          `${process.env.CDN_DOMAIN}`
        ],
        defaultPrivacyLevel: "mask-user-input",
        traceSampleRate: 100,
        beforeSend: (option == null ? void 0 : option.excludeAuthError) ? excludeAuthError2 : void 0
      });
      datadogRum2.startSessionReplayRecording();
    }
  };
  return {
    initDatadog
  };
}
export {
  datadogNextjs,
  datadogNuxtjs
};
